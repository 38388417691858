import { APIError } from '@cuidador/lib';
import { Avatar } from '@material-ui/core';
import { AxiosError } from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import HeaderWithTitle from '../../components/Headers/HeaderWithTitle';
import PscIcon from '../../components/Icons/PscIcon';
import LoadingBackdrop from '../../components/LoadingBackdrop';
import UnregistredShiftsMessage from './UnregistredShiftsMessage';
import { setQueuedData as setOfflineQueuedData } from '../../config/axios/offlineProxy/offlineQueue';
import { AuthContext } from '../../contexts/auth';
import { isSelfCare } from '../../contexts/permission';
import { ShiftInProgressContext } from '../../contexts/ShiftInProgress';
import useCanAccess from '../../hooks/useCanAccess';
import usePatient from '../../hooks/usePatient';
import useShift from '../../hooks/useShift';
import { resolveErrorMessage } from '../../utils/error';
import ShiftCalendarCard from './ShiftCalendarCard';
import { CalendarEntryDataType } from '@cuidador/database';
import {
  CenterContainer,
  Container,
  HeaderTitle,
  HistoryIcon,
  IconBackground,
  PhotoElement,
  ShiftHistoryButton,
  StyledBackIcon,
  StyledIconButton,
  Title,
} from './styles';

interface RouteParams {
  id: string;
}

const ShiftCalendar: React.FC = () => {
  const [shiftCalendar, setShiftCalendar] = useState<CalendarEntryDataType[]>(
    []
  );
  const [patientProfilePictureURL, setPatientProfilePictureURL] = useState('');
  const [isStartingShift, setIsStartingShift] = useState(false);

  const { refreshShiftInProgress, executionInProgress } = useContext(
    ShiftInProgressContext
  );
  const { userInfo } = useContext(AuthContext);
  const history = useHistory();
  const { id: patientId } = useParams<RouteParams>();
  const {
    getById: getPatientById,
    byId: patientById,
    loading: loadingPatient,
    getProfilePicture,
  } = usePatient();
  const {
    startShift,
    getShiftCalendarByPatientId,
    loading: loadingShiftCalendar,
  } = useShift();
  const { isAllowedToRead: isAllowedToReadShiftCalendar } = useCanAccess(
    'care/shift.upnext'
  );

  useEffect(() => {
    if (!isAllowedToReadShiftCalendar) {
      toast.error('Você não tem permissão para visualizar essa página');
      history.push('/');
    }
  }, [isAllowedToReadShiftCalendar]);

  useEffect(() => {
    getPatientById(Number(patientId));
    handleGetShiftCalendar();
    handleGetProfilePictureURL(Number(patientId));
  }, [patientId]);

  const patient = patientById[patientId];

  const handleGetShiftCalendar = () => {
    getShiftCalendarByPatientId(Number(patientId))
      .then((shiftCalendar) => {
        setShiftCalendar(shiftCalendar);
      })
      .catch((err: AxiosError<APIError>) => {
        const displayMessage = resolveErrorMessage(err);
        toast.error(displayMessage);
      });
  };

  const handleGetProfilePictureURL = async (id: number) => {
    try {
      return await getProfilePicture(id)
        .then(({ data: { signedUrl } }) => signedUrl)
        .then((url) => {
          if (!url) return;
          setPatientProfilePictureURL(url);
        });
    } catch (err) {
      // ignore patient without profile picture error
      if (!String(err).includes('404')) toast.error(err);
    }
  };

  const handleStartShift = async (shiftId: number) => {
    if (!!executionInProgress) {
      toast.error(
        'Você já tem um plantão em andamento. Encerre este para iniciar outro'
      );
      return;
    }

    setIsStartingShift(true);
    await startShift(shiftId)
      .then(() => {
        setOfflineQueuedData([]);
      })
      .then(() => {
        return refreshShiftInProgress();
      })
      .then(() => {
        isSelfCare(userInfo?.user)
          ? history.push(`/plantao/mensagem-de-motivacao`)
          : history.push(`/plantao/passado`);
      })
      .catch((err: AxiosError<APIError>) => {
        if (err.response?.status === 400) {
          const displayMessage = resolveErrorMessage(err);
          toast.error(displayMessage);
        } else {
          toast.error(
            `Erro ao iniciar ${
              isSelfCare(userInfo?.user) ? 'rotina' : 'plantão'
            }, tente novamente daqui a pouco.`
          );
        }
      })
      .finally(() => {
        setIsStartingShift(false);
      });
  };

  const isLoading = loadingPatient || loadingShiftCalendar || isStartingShift;

  if (isLoading) {
    return <LoadingBackdrop loading={isLoading} />;
  }

  return (
    <>
      <HeaderWithTitle
        title=""
        leftContent={
          <StyledIconButton
            edge="end"
            color="inherit"
            onClick={() => history.goBack()}
          >
            <StyledBackIcon />
          </StyledIconButton>
        }
        centerContent={
          <CenterContainer>
            <PhotoElement>
              {<Avatar src={patientProfilePictureURL} />}
            </PhotoElement>
            <HeaderTitle align="center">{patient?.name}</HeaderTitle>
          </CenterContainer>
        }
        rightContent={
          <IconBackground to="/pessoas-sob-cuidado">
            <PscIcon />
          </IconBackground>
        }
      />
      <Container>
        <Title variant="h6">Agenda de plantões</Title>
        <ShiftHistoryButton
          color="inherit"
          variant="outlined"
          onClick={() => {
            history.push(`/historico/plantao/paciente/${patient?.id}`);
          }}
        >
          <HistoryIcon />
          Histórico de plantões
        </ShiftHistoryButton>
        {!shiftCalendar.length && <UnregistredShiftsMessage />}
        {!!shiftCalendar.length && (
          <>
            {shiftCalendar.map((entry) => (
              <ShiftCalendarCard
                key={Number(entry.shiftId)}
                patientId={Number(patientId)}
                calendarEntry={entry}
                handleStartShift={async () =>
                  await handleStartShift(Number(entry.shiftId))
                }
              />
            ))}
          </>
        )}
      </Container>
    </>
  );
};

export default ShiftCalendar;
